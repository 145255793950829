import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  calculateItemsLength,
  normalizeProducts,
  sortAlphabetically,
} from "../../../../helpers/tools";
import { getSearchValues } from "../../../../Redux/Selectors/searchSelectors";
import { getSupplierActivities } from "../../../../Redux/Selectors/supplierSelectors";
import {
  getAllProductsShopRequest,
  resetAssignments,
  resetSelectedProducts,
  resetSummaryProducts,
  setPickUpAndDropOffBranch,
} from "../../redux/actions/shopActions";
import {
  getPricingData,
  getPricingIsFetching,
  getProductsIsFetching,
  getShopProducts,
  getSummary,
  getItems,
  getBranchSelected,
  getAssigmentsData,
} from "../../redux/selectors/ShopSelectors";
import { productModalShow } from "../../redux/actions/productModalActions";
import { getProductModalVisibility } from "../../redux/selectors/ProductModalSelectors";
import {
  getSupplierData,
  getSupplierSettings,
} from "../../../../Redux/Selectors/appConfigSelector";
import { generateMessage } from "../../utils";
import { useTranslation } from "react-i18next";
import useGlobalUtils from "../../../../hooks/useGlobalUtils";
import { isEmpty } from "lodash";

const QuoteContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [showPickUpAndDropOffModal, setShowPickUpAndDropOffModal] =
    useState(false);
  const searchValues = useSelector(getSearchValues);
  const settings = useSelector(getSupplierSettings);
  const { handleSendWhatsApp } = useGlobalUtils();
  const products = useSelector((state) =>
    getShopProducts(state, searchValues?.activities, searchValues?.searchByName)
  );
  const activities = useSelector(getSupplierActivities);
  const quoteSummary = useSelector(getSummary);
  const pricingData = useSelector(getPricingData);
  const isPricingFetching = useSelector(getPricingIsFetching);
  const isProductsFetching = useSelector(getProductsIsFetching);
  const isModalVisible = useSelector(getProductModalVisibility);
  const supplier = useSelector(getSupplierData);
  const selectedProducts = useSelector((state) =>
    getItems(state, normalizeProducts(quoteSummary?.products))
  );
  const { pickUpBranch, dropOffBranch } = useSelector(
    getBranchSelected,
    shallowEqual
  );
  const assignments = useSelector(getAssigmentsData);

  useEffect(() => {
    return () => {
      if (!isEmpty(assignments)) {
        dispatch(resetAssignments());
        dispatch(resetSelectedProducts());
      }
    };
  }, [assignments]);

  useEffect(() => {
    if (!isEmpty(selectedProducts)) {
      dispatch(resetSummaryProducts());
    }
  }, []);

  useEffect(() => {
    if (isEmpty(pickUpBranch) && isEmpty(dropOffBranch)) {
      dispatch(
        setPickUpAndDropOffBranch(supplier?._id, "pickUpBranch", supplier?.name)
      );
      dispatch(
        setPickUpAndDropOffBranch(
          supplier?._id,
          "dropOffBranch",
          supplier?.name
        )
      );
    }
  }, [pickUpBranch, dropOffBranch]);

  useEffect(() => {
    if (!quoteSummary.period) {
      return;
    }

    dispatch(
      getAllProductsShopRequest(
        {
          owner: pickUpBranch?._id ?? supplier._id,
          published: true,
          active: true,
          visibleOnWeb: true,
        },
        settings?.currency
      )
    );
  }, [quoteSummary.period, pickUpBranch]);

  const handleOnDetailsClick = (productId, optionType, productType) => {
    dispatch(productModalShow(true, productId, optionType, productType));
  };

  const handleOnDismissModal = () => {
    dispatch(productModalShow(false));
  };

  const handleOnSummaryClick = () => {
    const { onlinePaymentsEnabled, addMemberEnabled } = settings;
    const { items, currency, total } = pricingData;

    if (onlinePaymentsEnabled) {
      handleOnlinePayments(addMemberEnabled);
    } else {
      const message = generateMessage(items, currency, total, t);
      handleSendWhatsApp(message);
    }
  };

  const handleOnlinePayments = (addMember) => {
    const route = addMember ? "passengers" : "/shop/assignments";
    navigation(`${route}${window.location.search}`);
  };

  const handleShowPickUpAndDropOffModal = () => {
    setShowPickUpAndDropOffModal(!showPickUpAndDropOffModal);
  };

  return children({
    productsByCategory: products,
    searchName: searchValues?.searchByName,
    categories: sortAlphabetically(Object.keys(products)),
    quoteSummary,
    pricingData,
    itemsLength: calculateItemsLength(quoteSummary?.products),
    isPricingFetching,
    isProductsFetching,
    handleOnDetailsClick,
    handleOnDismissModal,
    supplierData: supplier,
    activities,
    selectedProducts,
    isModalVisible,
    handleOnSummaryClick,
    showPickUpAndDropOffModal,
    handleShowPickUpAndDropOffModal,
  });
};

export default QuoteContainer;
