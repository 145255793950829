import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import Button from "antd/es/button";
import useDaysLength from "../../../../hooks/useDaysLength";
import useProductsInConflict from "../../../../hooks/useProductsInConflict";
import ProductsSummary from "../ProductsSummary/ProductsSummary";
import EmptyPlaceholder from "../EmptyPlaceholder/EmptyPlaceholder";
import Disclaimer from "../../../../components/Disclaimer/Disclaimer";
import baseTheme from "../../../../config/baseTheme";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Text from "antd/es/typography/Text";
import BootstrapIcon from "../../../../components/BootstrapIcon/BoostrapIcon";
import { getSearchValues } from "../../../../Redux/Selectors/searchSelectors";
import { useSelector } from "react-redux";
import Price from "../../../../components/Price/Price";
import { listTypes } from "../../utils";
import ExperienceDates from "../../../../components/Dates/ExperienceDates";
import TicketsSummary from "../TicketsSummary/TicketsSummary";
import {
  Column,
  Container,
  Row,
  StyledTitle,
  WarningPickUpTimeButton,
  WarningPickUpTimeText,
  WarningPickUpTimeWrapper,
} from "./FloatingSummary.styles";
import DiscountInput from "../DiscountInput/DiscountInput";
import SpaceDates from "../../../../components/Dates/SpaceDates";
import {
  PickUpAndDropOffSummaryWrapper,
  StyledText,
} from "../../../../components/PickUpAndDropOffSummary/PickUpAndDropOff.styles";
import { getBranchSelected } from "../../redux/selectors/ShopSelectors";
import PickUpAndDropOff from "../../../../components/PickUpAndDropOffSummary/PickUpAndDropOff";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { PERIODS } from "../../../../helpers/tools";
import useIsMobile from "../../../../hooks/useIsMobile";

const FloatingSummary = ({
  pricingData,
  itemsLength,
  isPricingFetching,
  visible,
  expandedDefault = true,
  selectedProducts = [],
  onActionClick,
  isButtonEnabled = true,
  buttonLabel,
  period,
  showTotal = false,
  bookingType = listTypes.PRODUCT,
  from,
  title,
  validationMessage,
  quoteSummary,
  discounts,
  deleteText = false,
  to,
  handleShowPickUpAndDropOffModal,
  isExperience,
}) => {
  const times = useSelector((state) => getSearchValues(state));
  const { pickUpBranch, dropOffBranch } = useSelector(getBranchSelected);
  const isMobile = useIsMobile();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const dates = {
    from: params.from,
    to: params.to,
    pickupTime: params.pickupTime,
    dropoffTime: params.dropoffTime,
  };
  const daysLength = useDaysLength(
    quoteSummary?.period,
    params.from,
    params.to,
    params.pickupTime,
    params.dropoffTime
  );
  const { t } = useTranslation(["shop", "common"]);
  const { hasConflicts } = useProductsInConflict({
    products: selectedProducts,
    daysLength,
  });

  const hasItemsAndDatesSelected =
    isExperience || (itemsLength > 0 && dates.dropoffTime && dates.pickupTime);

  const isValidToContinue = () => {
    if (bookingType === listTypes.EXPERIENCE)
      return !isEmpty(validationMessage);
    else if (bookingType === listTypes.SPACE) {
      return false;
    } else return !params.pickupTime || !params.dropoffTime;
  };
  const renderProducts = () => {
    if (itemsLength > 0 && dates.dropoffTime && dates.pickupTime) {
      return (
        <ProductsSummary
          products={pricingData?.items}
          expanded={expandedDefault}
        />
      );
    }
    if (itemsLength > 0 && !dates.dropoffTime && !dates.pickupTime) {
      return (
        <WarningPickUpTimeWrapper>
          {isMobile ? (
            <WarningPickUpTimeButton
              type="link"
              onClick={handleShowPickUpAndDropOffModal}
            >
              {t("shop:warning.pickTime")}
            </WarningPickUpTimeButton>
          ) : (
            <>
              <WarningPickUpTimeText type="danger">
                <BootstrapIcon iconName="clock" /> {t("shop:warning.pickTime")}
              </WarningPickUpTimeText>
            </>
          )}
        </WarningPickUpTimeWrapper>
      );
    }
    if (itemsLength === 0 && expandedDefault) {
      return <EmptyPlaceholder />;
    }
  };

  const renderTickets = () => {
    if (!isEmpty(pricingData) && from) {
      return (
        <TicketsSummary pricingData={pricingData} deleteText={deleteText} />
      );
    } else {
      return <EmptyPlaceholder />;
    }
  };

  const renderExpanded = () => {
    return (
      <>
        <Text strong>{t("summary")}</Text>
        {bookingType === listTypes.SPACE && (
          <>
            <SpaceDates
              from={from || dates?.from}
              to={to || dates?.to}
              format="DD/MM/YY"
              period={period}
              daysQuantity={pricingData?.length}
            />
            {renderProducts()}
          </>
        )}
        {bookingType === listTypes.PRODUCT && (
          <>
            <PickUpAndDropOffSummaryWrapper>
              {pricingData?.length > 0 && (
                <StyledText>
                  <StyledText>
                    {period === PERIODS.day
                      ? t("serviceDays")
                      : t("serviceHours")}
                    :
                  </StyledText>{" "}
                  {period === PERIODS.hour ? (
                    <StyledText strong>
                      {t(period, { count: pricingData?.length })}
                    </StyledText>
                  ) : (
                    <Tooltip
                      overlayStyle={{ fontSize: 14 }}
                      title={t("daysQuantity_description")}
                    >
                      <StyledText strong>
                        {t(period, { count: pricingData?.length })}
                      </StyledText>{" "}
                      <InfoCircleOutlined />
                    </Tooltip>
                  )}
                </StyledText>
              )}

              <PickUpAndDropOff
                title={t("pickUp")}
                branchName={pickUpBranch?.name}
                selectedDate={dates?.from}
                selectedTime={times?.pickupTime}
              />
              <PickUpAndDropOff
                title={t("dropOff")}
                branchName={dropOffBranch?.name}
                selectedDate={dates?.to}
                selectedTime={times?.dropoffTime}
              />
            </PickUpAndDropOffSummaryWrapper>
            {renderProducts()}
          </>
        )}
        {bookingType === listTypes.EXPERIENCE && (
          <>
            <ExperienceDates
              from={from || dates?.from}
              period={period}
              daysQuantity={pricingData?.length}
            />
            {renderTickets()}
          </>
        )}
      </>
    );
  };

  const getDiscount = () => {
    if (
      isEmpty(pricingData?.discounts) ||
      isEmpty(pricingData?.discounts[0]?.code)
    ) {
      return null;
    }
    const discountData = pricingData.discounts[0];
    return {
      code: discountData.code,
      amountDiscount: `${discountData.aliquotDiscounted}%`,
    };
  };

  if (!visible) return null;
  return (
    <Container>
      <Column>
        {title && (
          <StyledTitle level={5} ellipsis={true} strong>
            {title}
          </StyledTitle>
        )}
        {renderExpanded()}
        {/* <ExpandableButton
          expanded={expanded}
          enabled={expandable}
          onClick={() => setExpanded(!expanded)}
        /> */}
        {!isEmpty(discounts) &&
          hasItemsAndDatesSelected &&
          !isPricingFetching && (
            <Row>
              <DiscountInput discount={getDiscount()} {...discounts} />
            </Row>
          )}
        <Row>
          <Price
            showTotal={showTotal || hasItemsAndDatesSelected}
            currencySymbol={pricingData?.currency?.symbol}
            currency={pricingData?.currency?.currency}
            price={pricingData?.total}
            isTotal
            isPricingFetching={isPricingFetching}
            discounts={pricingData?.discounts}
            rentalPeriodType={pricingData?.period}
          />
        </Row>
        <Row>
          <Button
            type="primary"
            shape="round"
            block
            size="large"
            disabled={
              isEmpty(pricingData) ||
              itemsLength === 0 ||
              hasConflicts ||
              isValidToContinue() ||
              !isButtonEnabled
            }
            onClick={onActionClick}
            data-testid="quote-button-submit"
          >
            {t(buttonLabel)}
          </Button>
        </Row>
        {(hasConflicts || !isEmpty(validationMessage)) && (
          <Disclaimer
            color={baseTheme.colors.red}
            bordered={false}
            text={t(
              `${
                validationMessage
                  ? validationMessage
                  : "shop:lessThanTheMinimumAllowed"
              }`
            )}
          />
        )}
      </Column>
    </Container>
  );
};

FloatingSummary.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.string,
  onActionClick: PropTypes.func,
  onExtraActionClick: PropTypes.func,
  daysQuantity: PropTypes.number,
  from: PropTypes.object,
  pricingData: PropTypes.shape({
    total: PropTypes.number,
  }),
  buttonLabel: PropTypes.string,
  discounts: PropTypes.shape({
    loading: PropTypes.bool,
    onApplyDiscount: PropTypes.func,
    onRemoveDiscount: PropTypes.func,
    discountError: PropTypes.bool,
  }),
};

FloatingSummary.defaultProps = {
  price: 0,
  currency: "CLP",
  onActionClick: () => {},
  onExtraActionClick: () => {},
  daysQuantity: 0,
  from: moment().format("DD/MM/YYYY"),
  to: moment().format("DD/MM/YYYY"),
  pricingData: {
    total: 0,
  },
  buttonLabel: "common:continue",
};

export default FloatingSummary;
